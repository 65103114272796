<template>
  <div class="sign-in-wrapper">

    <div class="sales-cta">
      <a href="https://orbitalstack.com/" target="blank">
        <img class="sign-in-logo" src="~@/assets/svg/sales-card.svg" alt="Schedule a Demo">
      </a> 

      <div class="cta">
      <p class="big">Rapid insights to guide your design decisions now, saving you from costly mistakes later.</p>
      <div class="button-row"><a class="button" href="https://meetings.hubspot.com/chrissy-lemmex/demo-meeting-link?__hstc=57679477.15ff98b6cf027bfc24c411091436bad3.1707572445976.1742327462682.1742331295376.629&__hssc=57679477.4.1742331295376&__hsfp=1838488382&uuid=221c1b6a-10d4-410b-988c-9c6f357f16b7" target="_blank">Schedule a Demo</a></div>
      </div>

    </div>

    <div class="form-wrapper">
      <div v-if="!showQuestionnaire" class="sign-in-form">
        <h4 :class="this.loadingState">{{ status }}</h4>
        <button @click="login()" class="auth"> <img src="~@/assets/svg/rwdi-logo.svg" alt="RWDI">RWDI</button>

        <div class="text-sep">
            <span>OR</span>
        </div>
        <notification-banner v-if="signupSuccess"
          theme='success'
          title='Success!'
          message='Please sign in with your new account.'
          :show-close-button="false" />
        <notification-banner v-if="authError"
          theme='error'
          title='Invalid Username or Password'
          message='Please re-enter your username or password'
          :show-close-button="false" />
        <form class="username closer" @submit.prevent="loginWithUsernamePassword(username, password)">

          <div class="closer form-row">
            <label for="username">Username
              <input type="text" id="username" name="username" v-model="username" autocomplete="username">
            </label>
          </div>

          <div class="form-row">
            <label for="pass">Password
              <input type="password" id="pass" name="password" required v-model="password" autocomplete="current-password">
            </label>
          </div>

          <div class="form-row checkbox-css">
            <input id="5b" type="checkbox">
            <label for="5b">
            <svg viewBox="0 0 100 100">
            <path fill="none" stroke-width="20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.1 52.1l24.4 24.4 53-53"/>
            </svg>
            <span>Remember me</span>
            </label>
          </div>

          <div>
            <button class="sign-in-button">Sign in</button>
          </div>

          <hr>

          <div class="button-row">
            <router-link :to="{ name: 'ResetPassword'}" class="ghost-button">Forgot your password?</router-link>
            <router-link :to="{ name: 'SignupPage'}" class="ghost-button">Sign up</router-link> <!-- temporarily disabling self-registration -->
          </div>

        </form>
      </div>
      
      <div v-if="showQuestionnaire" class="questionnaire">
        <notification-banner v-if="questionnaireError"
        theme='error'
        title='Failed to submit answers'
        message='Please try again'
        :show-close-button="false" />

        <FormulateForm
        @submit="submitQuestionnaireAnswers">
          <div class='no-top-margin-all-descendants'>
            <h4 class="input-title mb-2">We'd like to get to know you.</h4>
            <div>
              <FormulateInput placeholder="Please select..." name="role" type="select" label="What is your current job function/role?" 
                :options="functionRoleList"
                validation="required"
                @input="handleRoleOption"
                :validation-messages="{ required:'\'Job function/role\' is required' }" />
              <FormulateInput placeholder="Please type in your answer..." name="other_role" label="Which one?"
                v-if="roleIsOther"
                validation="required"
                type="text"
                v-model="otherRole"
                :validation-messages="{ required:'Please specify your function/role' }" >
              </FormulateInput>
              <FormulateInput placeholder="Please select..." name="found_os" type="select" label="How did you find out about Orbital Stack?" 
                :options="osHowList"
                validation="required"
                @input="handleOsOption"
                :validation-messages="{ required:'\'How did you find out about us\' is required' }" />
              <FormulateInput placeholder="Please type in your answer..." name="other_found" label="Where?"
                v-if="foundOsIsOther"
                validation="required"
                v-model="otherOS"
                type="text"
                :validation-messages="{ required:'Please specify how you found out about Orbital Stack' }" >
              </FormulateInput>
              <FormulateInput placeholder="Please select..." name="project" type="select" label="Do you currently have a project that could benefit from Orbital Stack's Solutions?" 
                :options="hasProjectList"
                validation="required"
                @input="handleProjectOption"
                :validation-messages="{ required:'\'Do you have a current project\' is required' }" />
              <FormulateInput placeholder="Please select..." name="marketing" type="select" label="Would you like to access Orbital Stack case studies, free educational events and special offers?" 
                :options="mktOptionList"
                validation="required"
                :style="{'max-width': 'none !important'}"
                @input="handleMarketingOption" 
                :validation-messages="{ required:'\'Marketing opt-in selection\' is required' }" />
              <FormulateInput class='submit-button' type="submit" :disabled="false">
                Submit
              </FormulateInput>
            </div>
          </div>
        </FormulateForm>
      </div>
    </div>
    <div class="sign-in-footer">
      <p class="smaller">©2025 <a href="https://www.orbitalstack.com/">Orbital Stack</a>. All rights reserved. Unauthorized duplication is strictly prohibited.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  AUTH_RESET,
  AUTH_OAUTH_REQUEST_REDIRECT_URL,
  AUTH_REQUEST,
  AUTH_LOGIN_USERNAME_PASSWORD,
  AUTH_QUESTIONNAIRE,
  AUTH_QUESTIONNAIRE_ANSWERS,
  GET_LOGGED_IN_USER
} from '@/store/actions/auth';
import { LOGGED_OUT, WAIT_FOR_OAUTH_RESPONSE, AUTHENTICATED, ERROR, SIGNUP_SUCCESS } from '@/store/statuses/auth';
import { NotificationBanner } from 'rwdi-widgets';

export default {
  name: 'LoginPage',
  components: {
    NotificationBanner
  },
  computed: {
    status() {
      if (this.authState.status === LOGGED_OUT || this.authState.status === ERROR || this.authState.status === SIGNUP_SUCCESS) {
        return 'Sign in with';
      }

      if (this.authState.status === AUTHENTICATED) {
        return 'Authenticated!';
      }

      return 'Authenticating...';
    },
    loadingState() {
      return this.userIsDoneLoading ? 'completed' : 'loading';
    },
    userIsDoneLoading() {
      return this.authState.status === LOGGED_OUT || this.authState.status === AUTHENTICATED || this.authState.status === ERROR;
    },
    authError() {
      return this.authState.status === ERROR;
    },
    signupSuccess() {
      return this.authState.status === SIGNUP_SUCCESS;
    },
    roleIsOther() {
      return this.selectedRole === 'Other';
    },
    foundOsIsOther() {
      return this.selectedOS === 'Other';
    },
    ...mapGetters(['authState','isQuestionnaireOn']),
    ...mapGetters(['loggedInUser'])
  },
  mounted() {
    const _mounted = async () => {
      if(this.authError) { 
        this.$store.dispatch(AUTH_RESET);
      }

      if (this.authState.status === WAIT_FOR_OAUTH_RESPONSE) {
        const redirect_path = await this.$store.dispatch(AUTH_REQUEST, this.$route.query);
        if (redirect_path) {
          if (await this.wasMKToffered()) {
            this.$route.query.from = redirect_path;
            this.$store.commit(AUTH_QUESTIONNAIRE, false);
            this.$router.push(this.$route.query.from || { name: 'AppContainer' }).catch(() => {});
          } else {
            this.showQuestionnaire = true;
            this.$store.commit(AUTH_QUESTIONNAIRE, true);
          }
        }
      }

      if (this.isQuestionnaireOn === 'true') {
        if (await this.wasMKToffered()) {
          this.$store.commit(AUTH_QUESTIONNAIRE, false);
          this.$router.push(this.$route.query.from || { name: 'AppContainer' }).catch(() => {});
        } else {
          this.showQuestionnaire = true;
        }
      }
    };
    return _mounted();
  },
  data() {
    return {
      username: null,
      password: null,
      showQuestionnaire: false,
      functionRoleList: [
        'Architect',
        'Civil Engineer', 
        'Structural Engineer', 
        'Façade Engineer',
        'Developer', 
        'Designer',
        'Urban Planner',
        'Landscape Architect', 
        'Building Owner', 
        'Other'
      ],
      selectedRole: null,
      otherRole: '',
      osHowList: [
        'An RWDI Project Was Shared with Me', 
        'An Email from RWDI', 
        'Someone From My Company Created My Account', 
        'Search Engine (Google, Yahoo, Bing etc.)',
        'Recommended by a Friend or Colleague', 
        'LinkedIn', 
        'Twitter', 
        'Webinar', 
        'An Blog, Whitepaper or Publication', 
        'Tradeshow or Conference', 
        'Other'
      ],
      selectedOS: null,
      otherOS: '',
      hasProjectList: [
        'Yes, I have one now.', 
        'No, but I often have projects that could.', 
        'No, I am just exploring.', 
        'I don\'t know.'
      ],
      selectedHasProject: '',
      mktOptionList: [{ value: 'true', label: 'Yes'}, { value: 'false', label: 'No'}],
      selectMkt: null,
      questionnaireError: false
    };
  },
  methods: {
    async login() {
      const auth_url_path = this.$route.path === '/' ? '' : this.$route.path;
      const auth_redirect_uri = `${document.location.origin}${auth_url_path}`;
      const original_path = this.$route.fullPath;
      await this.$store.dispatch(AUTH_OAUTH_REQUEST_REDIRECT_URL, { auth_redirect_uri, original_path });
    },
    async loginWithUsernamePassword(username, password) {
      await this.$store.dispatch(AUTH_LOGIN_USERNAME_PASSWORD, { username, password });
      if (this.authState.status === AUTHENTICATED) {
        if (await this.wasMKToffered()) {
          this.$router.push(this.$route.query.from || { name: 'AppContainer' }).catch(() => {});
          this.$store.commit(AUTH_QUESTIONNAIRE, false);
        } else {
          this.$store.commit(AUTH_QUESTIONNAIRE, true);
          this.showQuestionnaire = true;
        }
      }
    },
    handleRoleOption(value) {
      this.selectedRole = value;
    },
    handleOsOption(value) {
      this.selectedOS = value;
    },
    handleProjectOption(value) {
      this.selectedHasProject = value;
    },
    handleMarketingOption(value) {
      if (value == 'true') {
        this.selectMkt = true;
      }else{
        this.selectMkt = false;
      }
    },
    async submitQuestionnaireAnswers() {
      let questionnaire_answers = {
        role: this.roleIsOther? this.otherRole : this.selectedRole,
        os_source: this.foundOsIsOther? this.otherOS :  this.selectedOS,
        project: this.selectedHasProject,
        marketing_opt_in: this.selectMkt
      };
      await this.$store.dispatch(AUTH_QUESTIONNAIRE_ANSWERS, questionnaire_answers);
      if (this.isQuestionnaireOn === 'true') {
        this.questionnaireError = true;
      } else if (this.loggedInUser.company_single_user === true) {
        this.$router.push(this.$route.query.from || { name: 'CompanyAdministration', query: {addusers: true} }).catch(() => {});
      } else {
        this.$router.push(this.$route.query.from || { name: 'AppContainer' }).catch(() => {});
      }
    },
    async wasMKToffered() {
      await this.$store.dispatch(GET_LOGGED_IN_USER);
      // do not show the login questionnaire if either they have answered it before or if they are an old user
      return this.loggedInUser.marketing_opt_in_offered==true || new Date(this.loggedInUser.date_joined) < new Date(new Date('2023-05-11').getTime() + new Date('2023-05-11').getTimezoneOffset()*60000);
    }
  }
};
</script>

<style scoped>

*, * > * {
  box-sizing: border-box!important;
}

body, html {
  height: 100vh;
  }

.ghost-button {
  font-size: 0.75em;
  text-align: right;
  font-weight: 700;
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: var(--primary-navy);
}

.sales-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  background-color: var(--primary-navy);
}

.sales-cta {
  color: #fff;
}

.cta {
  display: none;
}

.sign-in-footer {
  color: #fff;
  padding: 1em;
  text-align: center;
}

.sign-in-footer a {
  color: #fff;
}

a.button {
  text-decoration: none;
}

.sales-cta a {
  color: #fff;
  margin: 0 auto;
}

.sign-in-logo {
  width: 100%;
  height: auto;
  max-width: 15.625rem;
  padding: 1em

}

.form-wrapper {
  margin: 0;
  flex-grow: 2;
}

.sign-in-form {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0.188rem rgba(0, 0, 0, 0.1);
  max-width: 25rem;
  margin: 0 auto;
}

.sign-in-form .button-row {
  justify-content: space-between;
}

.sign-in-form a {
  align-self: center;
}

.sign-in-form h4 {
  font-size: 1.25em;
}

.sign-in-button {
  width: 100%;
}


/* Text Separator */

.text-sep {
  display: inline-block;
  width: 100%;
  background: inherit;
  text-align: center;
  color: var(--grey-700);
  font-weight: 700;
  position: relative;
  overflow: hidden;
}

.text-sep span {
  background-color: #fff;
  display: inline-block;
  padding: 0 0.5em;
  position: relative;
}

.text-sep:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: calc(50% - 0.125rem);
  width: 100%;
  height: 0.188rem;
  background-color: var(--grey-300);
}


/* Oauth button */

.auth {
  color: var(--grey-700);
  background-color: #fff;
  border: 0.063rem solid var(--grey-400);
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0.5em
}

.auth img {
  display: block;
  margin: 0 0.75em 0 0;
  height: 2em;
  width: auto;
}

.auth:hover,
.auth:active {
  color: var(--grey-700);
  background-color: var(--grey-100);
  text-decoration: none;
}

.checkbox-css label:before {
  margin-right: 0.5em;
}

.no-top-margin-all-descendants * {
	margin-bottom: 0.5em;
}
.no-top-margin-all-descendants .input-title{
  margin-bottom: 1.5em !important;
  margin-top: 0.2em !important;
}

.submit-button {
  margin-top: 2em;
}

.questionnaire {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0.188rem rgba(0, 0, 0, 0.1);
  max-width: 30rem;
  margin: 0 auto;
}

@media screen and (min-width: 52rem) {

  .sign-in-wrapper {
    flex-direction: row;
    background-color: var(--grey-200);
  }

  .cta {
    display: block;
    margin: auto 0;
  }

  .sales-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    max-width: 25rem;
    height: 100%;
    text-align: center;
    padding: 1.5rem;
    background-color: var(--primary-navy);
  }

  .sign-in-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 25rem;
  }
}

</style>

<style>
.submit-button .formulate-input-wrapper .formulate-input-element--submit button{
  background-color: var(--primary-blue);
  width: 100%;
  text-align: center !important;
  margin: 0 auto;
  display: inline;
  border: 0.66px solid rgb(0,84,136);
}
.formulate-input .formulate-input-wrapper .formulate-input-element {
  max-width: none !important;
}
</style>